<template>
	<div style="width: 100%;" :style="{height:(type=='bar'?'85%':'100%')}"  :id="id" ref="echarts"></div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";

moment.locale("zh-cn");

export default {
	name: "histogram",
	props: ["id", "data", "type", "Xname"],
	data() {
		return {
			myChart: ""
		};
	},
	mounted() {
		if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
			this.myChart.dispose();
		}
		this.myChart = echarts.init(document.getElementById(this.id));
		this.drawCharts();
	},
	watch: {
		data: {
			// 在父组件数据发生改变后子组件要做到实时更新,就需要子组件用watch来监听数据的变化
			// 看情况是否需要newValue和oldValue之间值比较
			handler() {
				this.drawCharts();
			},
			deep: true
		}
	},
	methods: {
		drawCharts() {
			let that = this;
			var series = [];
			let arr = this.data.listscore.map(i => {
				return i.name;
			});
			let yValue = this.data.listscore.map(i => {
				return i.yValue;
			});
			for (let i = 0; i < this.data.listscore.length; i++) {
				let a = {
					name: arr[i],
					type: this.type,
					data: yValue[i]
				};
				series.push(a);
                if (this.type === "bar") {
                    series[i]["barWidth"] = 15;
                    series[i]["barGap"] = "50%";
                    series[i]["itemStyle"] = {
                        borderRadius: [10, 10, 0, 0]
                    };
                }
				if (this.type === "line") {
					series[i]["symbolSize"] = 5;
				}
				/*if (this.id === "inOut" || this.id === "earnings") {
					series[i]["label"] = {
						show: true,
						position: "outside",
						fontSize: 16,
						color: "#373D5B"
					};
				}*/
			}
			that.myChart.setOption({
				tooltip: {
					trigger: "axis",
				/*	axisPointer: {
						type: "shadow"
					},*/
					backgroundColor:'rgba(0, 103, 166, 0.25)',
					formatter: function(params) {
						let res = "<div><p>" + params[0].name + "</p></div>";
						for (let i = 0; i < params.length; i++) {
							res += "<p>"  + params[i].seriesName + " : " + params[i].data + "</p>";
						}
						return res;
					},
					axisPointer: {
						type: "cross",
						crossStyle: {
							color: "rgba(90, 177, 239, 0.1)"
						}
					},
					extraCssText: 'box-shadow: 0px 0px 15px 0px rgba(72, 77, 94, 0.1);border-radius: 7px;',
					borderWidth: 2,
					borderColor: "#0067A6",
					padding: [2, 20],
					textStyle:{
						color: "#ffffff",
						decoration: "none",
						fontSize: 14
					}
					/*label: {

					}*/
				},
				grid: {
					left: "1%",
					right: "1%",
					top: "4%",
					bottom: "5%",
					containLabel: true
				},
				dataZoom: [
					{
						type: "slider",
						show: true,
						startValue: 0,//默认为0
						endValue: 6,//默认为100
						xAxisIndex: [0],
						handleSize: 0,//滑动条的 左右2个滑动条的大小
						height: 5,//组件高度
						bottom: 0,//右边的距离
						borderColor: "#e3e3e3",
						fillerColor: "#51B7F9",
						borderRadius: 10,
						backgroundColor: "#e3e3e3",//两边未选中的滑动条区域的颜色
						showDataShadow: false,//是否显示数据阴影 默认auto
						showDetail: false,//即拖拽时候是否显示详细数值信息 默认true
						realtime: true, //是否实时更新
						filterMode: "filter",
						zlevel: -10
					},
					//以下重点： 让鼠标滚动从缩放变成移动
					{
						type: "inside",
						xAxisIndex: [0],
						zoomOnMouseWheel: false, //滚轮不触发缩放
						moveOnMouseMove: true,   //鼠标移动触发平移
						moveOnMouseWheel: true  //鼠标滚轮触发平移
					}
				],
				/*legend: {
					data: arr,
					x: "right",
					padding: [0, 0, 0, 0],
					selectedMode: false,
					textStyle:{
						fontSize: 12,//字体大小
						color: '#ffffff'//字体颜色
					},
				},*/
				/*toolbox: {
					show: true,
					orient: "vertical",
					left: "right",
					top: "center"
				},*/
				xAxis: [
					{
						name: this.Xname,
						type: "category",
						axisTick: { show: false },
						axisLabel: {
							show: false
						},
						data: this.data.xValue
					}
				],
				yAxis: [
					{
						min: 0,
						/*max: 24,
						interval: 4,*/
						type: "value",
						axisLabel: {
							formatter: "{value}" + this.data.Yunit,
							color: "#5AB1EF"
						},
						splitLine: {//格线样式
							lineStyle: {
								color: "rgba(90, 177, 239, 0.5)"
							}
						}
					}
				],
				color: this.data.colors,
				series: series
			});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
